.common_account_drawer {
  .platform_form_item {
    .platform_img {
      width: 94px !important;
      height: 18px;
    }
  }
  .el-drawer__body {
    .el-form {
      .el-form-item {
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
.tnc_checkbox {
  .el-checkbox__label {
    @include rtl-sass-prop(padding-left, padding-right, 8px);
    .tnc_label {
      display: flex;
      align-items: center;
      .tnc {
        display: flex;
        align-items: center;
        p {
          color: $text-link;
        }
      }
    }
  }
}
/deep/ .tnc_text_box {
  li {
    font-size: 12px;
    color: $text-secondary;
    line-height: 22px;
    a {
      color: $text-link;
      text-decoration: underline;
    }
  }
}
.confirm_pamm_dialog_body {
  text-align: center;
  .required_icon,
  .confirm_tip {
    color: $danger;
  }
}
