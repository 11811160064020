#AccountPage {
  .incomplete_remind {
    margin-bottom: 16px;
  }
  .create_Btn {
    display: flex;
    justify-content: flex-end;
  }

  .account_cards {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;

    .account_item {
      margin-bottom: 16px;
      cursor: pointer;

      .item_box {
        width: 100%;
        padding: 24px;
        min-height: 170px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 0px 8px 1px rgba(122, 134, 153, 0.15);

        &:hover {
          box-shadow: 0px 8px 20px rgba(14, 18, 54, 0.1);
        }

        .item_top {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .top_left {
            display: flex;
            align-items: center;
            font-size: 14px;

            .server_category {
              display: inline-block;
              background-color: #536bec;
              font-size: 12px;
              color: #fff;
              padding: 3px 8px;
              border-radius: 4px;
              @include rtl-sass-prop(margin-right, margin-left, 8px);
            }

            .account_num {
              color: $text-primary;
            }

            .pending_status {
              display: flex;
              align-items: center;
              > span {
                display: inline-block;
                @include rtl-sass-prop(margin-right, margin-left, 8px);
              }
            }
          }

          .setting_box {
            &:hover {
              color: $primary;
            }
          }
        }

        .item_center {
          margin-top: 24px;
          min-height: 36px;
          display: flex;
          align-items: center;

          .equity_box {
            font-size: 24px;
            font-weight: 700;
            color: $text-title;
            @include rtl-sass-prop(margin-left, margin-right, 8px);
          }

          .currency_box {
            display: inline-block;
            padding: 3px 8px;
            font-size: 12px;
            color: $text-secondary;
            background-color: #ededf2;
            border-radius: 10px;
            @include rtl-sass-prop(margin-left, margin-right, 8px);
          }
        }

        .item_bottom {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-top: 8px;
          min-height: 34px;

          .bottom_left {
            display: flex;
            align-items: baseline;
            color: $text-secondary;
            font-size: 14px;
          }
        }
        &.inactive_item_box {
          background-color: #fafafa;
          box-shadow: 0px 0px 8px 1px rgba(122, 134, 153, 0.15);
          .item_top {
            .server_category {
              background-color: #f8f8fd;
              color: #acacbf;
            }
            .account_num {
              color: #acacbf;
            }
          }
          .item_center {
            #CurrencyIcon {
              background-color: #acacbf !important;
            }
            .equity_box {
              color: #acacbf;
            }
            .currency_box {
              color: #7a8699;
            }
          }
          .item_bottom {
            .bottom_left {
              color: #acacbf;
            }
            .bottom_right {
              display: none;
            }
          }
        }
      }
    }
  }

  .active_color {
    color: #30bf58;
  }
  .inactive_color {
    color: #acacbf;
  }
  .pending_color {
    color: #f39924;
  }
  .rejected_color {
    color: #cd2a2a;
  }
}
.setting_popover {
  &.el-popover {
    min-width: 88px;
    padding: 8px;
    border: none;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px 1px rgba(122, 134, 153, 0.15);

    .popover_btn_list {
      .btn_item {
        display: flex;
        align-items: center;
        .el-button {
          width: 100%;
          height: 40px;
          border: none;
        }
        .prompt_icon {
          cursor: pointer;
        }
        .icon {
          line-height: normal;
        }
      }
    }
  }
}

.home_status_content {
  text-align: center;
  padding-top: 20px;
}

.empty_box {
  width: 100%;
  min-height: 700px;
}

@media (max-width: 550px) {
  #AccountPage {
    .account_cards {
      .account_item {
        .item_box {
          padding: 24px 15px;
          .item_bottom {
            flex-direction: column;
            .bottom_left {
              width: 100%;
              padding-top: 10px;
              justify-content: flex-start;
            }
            .bottom_right {
              padding-top: 16px;
            }
          }
        }
      }
    }
  }
}
