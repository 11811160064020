.reset-balance-dialog /deep/ .el-dialog__body {
  padding: 50px 50px 45px !important;

  .top {
    .name {
      font-size: 15px;
      color: $dark-gray;
      text-transform: uppercase;
      line-height: 1.2;
      margin-bottom: 20px;
    }

    .number {
      font-size: 15px;
      color: $bottle-blue;
      text-transform: uppercase;
      line-height: 1.2;
    }
  }

  .form-box {
    margin-top: 40px;

    .btn-box {
      display: grid;
      grid-template-columns: 45% 45%;
      column-gap: 10%;
      margin-top: 60px;
      .long-text-btn {
        white-space: initial;
      }
    }
  }
}

@media (max-width: 550px) {
  .reset-balance-dialog /deep/ .el-dialog__body {
    padding: 20px !important;

    .top {
      grid-template-columns: 48% 48%;
      column-gap: 4%;
      margin-bottom: 10px;
    }

    .form-box {
      .btn-box {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        row-gap: 20px;
        margin-top: 40px;
      }
    }
  }
}
